@use "variables";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

$blue: variables.$primary-500;
$cyan: variables.$secondary-500;

$primary: $blue;
$secondary: $cyan;
$nav-link-color: $cyan;
$nav-link-font-size: 1.1em;
