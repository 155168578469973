@import "bootstrap";

.sidebar-container {
  width: 230px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #282263;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.sidebar-header {
  margin-top: 10px;
  margin-bottom: 30px;
}

.company-label {
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

.company-dropdown {
  margin-top: 10px;
  color: $blue;
}

.sidebar-navigation {
  flex-grow: 1;
}

.nav-item {
  margin-bottom: 10px;
}

.nav-link {
  font-size: 1.3em;
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    color: #03aeef;
    text-decoration: underline;
  }
  .svg-inline--fa {
    width: 30px;
  }
}

.sidebar-footer {
  text-align: center;
  padding: 10px 0;
}

.user-profile {
  font-size: 0.9em;
}
