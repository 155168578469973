.user-table {
  max-width: 1200px;
  tr {
    // &:hover {
    //   td {
    //     background: lightgray !important;
    //     cursor: pointer;
    //   }
    // }
  }

  td {
    color: black !important;
    font-size: 16px;
    line-height: 1.3;
  }

  thead {
    span {
      font-weight: 400;
      user-select: none;
      color: darkgray;
      white-space: break-spaces;
    }

    .sorting {
      font-size: 14px;
      margin-left: 4px;
    }

    tr {
      th {
        padding: 0 16px;
        height: 40px;
        vertical-align: middle;

        &:first-child {
          padding: 0 24px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0 16px;
        height: 64px;
        vertical-align: middle;
        min-width: 200px;

        &:first-child {
          padding: 0 24px;
        }
      }
    }
  }

  :global {
    .right {
      justify-content: right;
    }
  }
}
