.login-button {
  background-color: #03acee;
  border: 1px solid #03acee;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 8px 20px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  height: 50px;
  &:hover {
    background-color: #03acfe;
    border: 1px solid #006ea1;
    color: #fff;
  }
  &:active {
    background-color: #03acfe;
    border: 1px solid #006ea1;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }
}
