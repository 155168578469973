.device-card-layout {
  width: 330px;
  height: 450px;
  background-color: rgb(240, 252, 252);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  border: solid 1px rgb(223, 235, 235);
  align-items: center;
}

.description-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
