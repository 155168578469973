.unit-not-selected {
  background-color: lightgray;
  border: 3px solid darkgray;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.unit-selected {
  background-color: white;
  border: 3px solid #03acee;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}
